import React, { useState, useEffect } from "react";
import tableImage from '../assets/images/chair.png';
import { Popconfirm, Card, Modal, Button, Table, Tag, Collapse, message, Badge } from 'antd';
import axios from 'axios';
import QRCode from 'qrcode.react';
import {
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons";
const TableList = ({ table }) => {

  const { Meta } = Card;
  const [visible, setVisible, setLoading, loading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tableNumber, setTableNumber] = useState(table.table_number);
  const [tableOrders, setTableOrders] = useState({ orders: [] });
  const [editStatus, setEditStatus] = useState(null);
  const [qrVisible, setQrVisible] = useState(false);
  const [taxes, setTaxes] = useState([]);

  const getAllOrders = async () => {
    try {

      const { data } = await axios.get("/api/orders/get-order");

      setOrderData(data);

      console.log(data);

    } catch (error) {
      console.log(error);
    }

  };
  useEffect(() => {

    getAllOrders();
  }, []);


  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    let tableOrders = [];
    const fetchTableOrders = async () => {
      try {
        const { data: ordersData } = await axios.get(`/api/orders/get-order?table=${tableNumber}`);
        ordersData.forEach(order => {
          const { order_tablenumber } = order;
          if (!tableOrders[order_tablenumber]) {
            tableOrders[order_tablenumber] = [];
          }
          tableOrders[order_tablenumber].push(order);
        });
        setTableOrders(tableOrders);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTableOrders();
  }, [table.table_number]);

  const fetchData = async (tableNumber) => {
    try {
      const ordersData = tableOrders[tableNumber] || [];
      if (JSON.stringify(ordersData) !== JSON.stringify(orders)) {
        console.log('Orders data:', ordersData);
        setOrders(ordersData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(table.table_number);
  }, [fetchData, table.table_number]);

  const fetchTaxes = async () => {
    try {
      const response = await axios.get('/api/taxes/get-taxes');
      setTaxes(response.data); 
    } catch (error) {
      console.error("Error fetching taxes:", error);
      message.error("Failed to fetch taxes.");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const showModal = () => {
    fetchTaxes();
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleEditOrderStatus = async (status) => {
    try {
        // Get unique oids from orders
        const uniqueOids = [...new Set(orders.map(order => order.oid))];

      // Create an array of orders to be updated
      const ordersToUpdate = uniqueOids.map(oid => ({
                orderId: oid,
        order_progressstatus: status, // Pass the desired status ("Kitchen", "Receive", etc.)
      }));

      // Send a single request with the orders array
      const response = await axios.put('/api/orders/edit-order', {
        orders: ordersToUpdate
      });
  
      if (response.status === 200) {
        window.location.reload(); // Reload the page after successful update
        } else {
            console.error('Some orders failed to update');
        }
    } catch (error) {
        console.error('Error:', error);
    }
  };

  const handleEditOneOrderStatus = async (value, status) => {
    try {
      await axios.put("/api/orders/edit-order", { orderArrayId: value._id, order_progressstatus: status })
        .then(response => {
          console.log(response.data);
          message.success("Order Updated Successfully!");
          setEditStatus(null);


        })
        .catch(error => {
          console.log(error);
          message.error("Something went wrong!");
        });
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
  };
  const handleDeleteOrder = async (record) => {
    try {
      await axios.post("/api/orders/delete-order", { orderArrayId: record._id });
      message.success('Order Deleted Successfully')
      getAllOrders();
    } catch (error) {
      message.error('Something Went Wrong')
      console.log(error);
    }
  }

  const handleReceiptClick = async () => {
    try {
      const url = `http://adminpos.ergrouptech.com/bills?table=${tableNumber}`;
      window.location.href = url;
    } catch (error) {
      console.error("Error updating the order status:", error);
      message.error("Failed to update order status.");
    }
  };

   const calculateTotalWithTaxes = (order) => {
    const totalWithoutTax = order.result.reduce(
      (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
      0
    );
    const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
    return (totalWithoutTax + totalTax).toFixed(2);
  };

  const calculateTotalWithoutTaxes = (order) => {
    const totalWithoutTax = order.result.reduce(
      (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
      0
    );
   
    return (totalWithoutTax).toFixed(2);
  };

  const { Panel } = Collapse;

  const showQrModal = () => {
    setQrVisible(true);
  };
  
  const hideQrModal = () => {
    setQrVisible(false);
  };

  const statusBadge = (status) => {
    let color;
    switch (status) {
      case 'Kitchen':
        color = 'yellow';
        break;
      case 'Receive':
        color = 'green';
        break;
      case 'Preparing':
        color = 'blue';
        break;
      default:
        color = 'gray';
    }
    return <Badge.Ribbon color={color} text={status} />;
  };

  return (
    <div>

      <Card
        hoverable
        style={{ width: 230, marginBottom: 20 }}
        cover={<img alt={table.table_number} src={tableImage} />}
        onClick={() => showModal(table.table_number)}

      >
        <Meta
          title={`Table ${table.table_number}`}
          description={
            <>
              {console.log('Table Orders for Table Number:', table.table_number)}
              {console.log('tableOrders:', tableOrders)}
              {/* Check if the table has orders and if any order has order_status as true */}
            <span
              style={{
                  color: tableOrders[table.table_number] &&
                    tableOrders[table.table_number].some(order => order.order_status)
                    ? "red"
                    : "green",
                fontWeight: "bold",
              }}
            >
                {tableOrders[table.table_number] &&
                  tableOrders[table.table_number].some(order => order.order_status)
                  ? "Busy"
                  : "Available"}
            </span>
            </>
          }
        />
      </Card>
      <Modal
        open={open}
        title={`Table ${table.table_number}`}
        onOk={handleOk}

        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button
            key="showQR"
            type="default"
            onClick={showQrModal}
          >
            Show QR
          </Button>,
          <Button
            key="back"
            onClick={() => {
              window.open(`https://pos.ergrouptech.com/shop?table=${table.table_number}`, '_blank');
            }}
          >
            Order More
          </Button>,
          <Button
            key="link"
            href=""
            type="primary"
            loading={loading}
            onClick={() => handleEditOrderStatus("Kitchen")}
            disabled={tableOrders[table.table_number] &&
              tableOrders[table.table_number].some(order => order.order_status)
              ? false
              : true}
          >
            Print to Kitchen
          </Button>,
          <Button key="submit" type="primary" danger 
          loading={loading} 
          disabled={tableOrders[table.table_number] &&
            tableOrders[table.table_number].some(order => order.order_status)
            ? false
            : true}
          onClick={handleReceiptClick}>
            Checkout
          </Button>
        ]}
      >
        <Collapse defaultActiveKey={orders.map((order) => order.oid)} onChange={onChange}><span style={{ marginLeft: '10px' }}>
                    {statusBadge((order) => order.order_progressstatus)}
                  </span>
          {orders.map((order) => (
            <Panel header={<strong>Order ID: #{order.oid}</strong>} key={order.oid}>
              <Table
                bordered
                columns={[
                  {
                    title: 'No',
                    key: 'index',
                    render: (text, record, recordIndex) => (
                      <span>{recordIndex + 1}</span>
                    ),
                  },
                  {
                    title: "Food Name",
                    dataIndex: "orderArray_productName",
                    render: (orderArray_productName) => orderArray_productName,
                  },
                  {
                    title: "Price(RM)",
                    dataIndex: "orderArray_price",
                    render: (orderArray_price) => `RM${parseFloat(orderArray_price).toFixed(2)}`,
                  },
                  {
                    title: "Quantity",
                    dataIndex: "orderArray_quantity",
                    render: (orderArray_quantity) => orderArray_quantity,
                  },
                  {
                    title: "Status",
                    dataIndex: "order.order_progressstatus",  // Updated to use 'order_progressstatus'
                    render: (order_progressstatus) => {
                      
                      let displayText = "";
                      let color = "";
                      let backgroundColor = "";
                      let borderColor = "";
  
                      if (order.order_progressstatus === "") {
                        displayText = "Ordering";
                        color = "#d4380d";
                        backgroundColor = "#fff2e8";
                        borderColor = "#ffbb96";
                      } else if (order.order_progressstatus === "Kitchen") {
                        displayText = "Preparing";
                        color = "#d4b106";
                        backgroundColor = "#fffbe6";
                        borderColor = "#ffe58f";
                      } else if (order.order_progressstatus === "Receive") {
                        displayText = "Enjoy!";
                        color = "green";
                        backgroundColor = "#f6ffed";
                        borderColor = "#b7eb8f";
                      }
  
                      return (
                      <Tag
                        style={{
                            color: color,
                            backgroundColor: backgroundColor,
                            borderColor: borderColor,
                        }}
                      >
                          {displayText}
                      </Tag>
                      );
                    },
                  },
                  {
                    title: "Total Price(RM)",
                    dataIndex: "orderArray_totalPrice",
                    render: (_, record) => (
                      <span>RM{(record.orderArray_price * record.orderArray_quantity).toFixed(2)}</span>
                    ),
                  },
                  {
                    title: "Actions",
                    render: (_, record) => (
                      <div>
                        <CheckOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={() => { setEditStatus(null); handleEditOrderStatus("Receive") }}
                        />
                        <Popconfirm
                          title="Are you sure you want to delete this record?"
                          onConfirm={() => handleDeleteOrder(record)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            style={{ cursor: "pointer", marginLeft: 8 }}
                          />
                        </Popconfirm>
                      </div>
                    ),
                  },
                ]}
                dataSource={order.result || []}
              />
              <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.3em" }}>
                Total Price: RM{" "}
                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                  {calculateTotalWithoutTaxes(order)}
                </span>
              </div>

              <div style={{ textAlign: "right", fontSize: "1.3em" }}>
                Total Tax: RM{" "}
                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                  {(calculateTotalWithTaxes(order) - calculateTotalWithoutTaxes(order)).toFixed(2)}
                </span>
              </div>

              <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.5em" }}>
                Total Price with Taxes: RM{" "}
                <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                  {calculateTotalWithTaxes(order)}
                </span>
              </div>
            </Panel>
          ))}
        </Collapse>
      </Modal>
      <Modal
        open={qrVisible}
        onOk={hideQrModal}
        onCancel={hideQrModal}
        footer={[
          <Button
            key="print"
            type="primary"
            onClick={() => window.print()}
          >
            Print
          </Button>
        ]}
      >
        <div style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}>
          QR Code
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <QRCode value={`https://pos.ergrouptech.com/shop?table=${table.table_number}`} />
        </div>
        <div style={{ textAlign: "center", marginTop: "10px", fontSize: "18px", fontWeight: "bold" }}>
          Table {table.table_number}
        </div>
      </Modal>
    </div>
  );
};

export default TableList;
